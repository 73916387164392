import React, { useState } from "react";
// import { Col } from "react-bootstrap";
import contactStyle from "../../components/non-panels/ContactForm.module.scss";
// import ReactMarkdown from "react-markdown/with-html";
// import { LinkRenderer } from "../../utils/utilities";
// import Image from "gatsby-image";

//components
import PanelFoundation from "../../components/panels/PanelFoundation";
import HubSpotForm from "../../components/non-panels/forms/HubSpotForm";
import Hero from "../../components/panels/Hero";
import ComponentHeader from "../../components/panels/ComponentHeader";
import SwitchComponent from "../../components/panels/Switch";
import useSetPageData from "../../utils/useSetPageData";
// import ListItem from "../../components/panels/ListItem";
// import ListItem2 from "../../components/panels/ListItem2";
import SEO from "../../components/seo";

//styles
import s from "./public-sector.module.scss";

import rpaIcon from "../../images/manage-rpa/icon.png";

import image1 from "../../images/public-sector/01.jpg";
import image2 from "../../images/public-sector/02.jpg";
import image3 from "../../images/public-sector/03.jpg";
import image4 from "../../images/public-sector/04.jpg";

//styles
// import { Link } from "gatsby";

export const BlogSubscribeFormReplacementMessage = () => (
  <div
    className={contactStyle.thanks}
    style={{
      justifyContent: "center",
      alignItems: "center",
      alignSelf: "center"
    }}
  >
    <h5
      style={{
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "center"
      }}
    >
      Thank You!
    </h5>
    <p style={{ textAlign: "center" }}>
      We will reach out to you soon regarding your quote.
    </p>
  </div>
);

const PublicSectorServices = ({ location, data }) => {
  useSetPageData(location);
  //   const { raas_cards } = data.strapiPsRpa;
  //   const [showModal, setShowModal] = useState(false);

  //   const handleCloseModal = () => setShowModal(false);
  //   const handleShowModal = () => setShowModal(true);

  const showFormOverlay = value => {
    let form = document.querySelector("#hubSpotFormOverlay");
    if (value) {
      form.style.display = "block";
    } else {
      form.style.display = "none";
    }
  };

  return (
    <>
      <SEO
        {...{
          title: `Public Sector Services`,
          description:
            "Digital Automation Implementation and Managed Services Provider for Support and Maintenance | NITCO Inc."
        }}
      />

      <Hero
        heading={"Public Sector Services"}
        iconComponent={<img src={rpaIcon} />}
        // icon={hero[0].service_icon.childImageSharp.fixed}
        // videoImage={hero[0].services_main_image.childImageSharp.fixed.src}
        // videoLink={"/videos/final-consulting.mp4"}
      />
      {/* <ComponentHeader
        rootClassName={`bg-light text-primary`}
        text={`Public Sector Services Provider`}
      /> */}
      {/* <div style={{ marginTop: "40px" }}></div> */}
      {/* <SwitchComponent
        evenClassName={`bg-light text-primary pt-5 pb-5`}
        oddClassName={`bg-light text-primary pt-5 pb-5`}
        contents={[
          {
            heading:
              "NITCO’s RPA as a Service is Your Outsourced IT Department for Robotic Process Automation",
            image: <img src={NITCO_1} />,
            // image: <Image fluid={it_overview_image.childImageSharp.fluid} />,
            content: (
              <p>
                Robotic process automation holds great promise for organizations
                of all sizes to become more competitive in the digital
                automation environment. Understandably, some organizations
                simply don’t have the on-site resources (business analysts,
                developers, or project managers) to support their needs for
                digital automation. Smaller organizations or even new
                organizations just starting with digital automations need a
                cost-effective approach to handle software licensing,
                implementations, development, and support. NITCO can help.
              </p>
            )
            // <ReactMarkdown
            //   source={overview_description}
            //   escapeHtml={false}
            //   renderers={{ link: LinkRenderer }}
            // />
          },
          {
            heading: "Big Benefits from RPA as a Service",
            image: <img src={NITCO_2} />,
            // image: <Image fluid={it_overview_image.childImageSharp.fluid} />,
            content: (
              <p>
                Robotic Process Automation, or RPA, is your personal
                assistant—designed to help you perform a wide range of business
                functions. But the capabilities to handle this in-house are
                limited or nonexistent for most organizations. That’s where RPA
                as a Service can come into play. We’ll handle all aspects of
                your RPA) needs—from identifying and assessing automation
                opportunities and building automations, to including the cost of
                licensing and hosting, to ongoing maintenance and support—and
                much more. All for one low monthly fee based on your needs.
              </p>
            )
          }
        ]}
      /> */}

      <ComponentHeader
        rootClassName={`bg-white`}
        textClassName={`text-primary`}
        text={
          "Public Sector Provider for Digital Automation Software and Professional Services"
        }
      />
      <PanelFoundation rootClassName={`panel-padding`}>
        {/* <Col sm={12}>
          <table>
            <tr>
              <th>Vendor</th>
              <th>Application</th>
              <th>Date</th>
              <th>Version</th>
              <th>VPAT Document</th>
            </tr>
            <tr>
              <td>Kore.ai</td>
              <td>Kore.ai Conversational AI Platform</td>
              <td>October 2021</td>
              <td>2.3 WCAG</td>
              <td>
                <a href="http://52.254.20.191/uploads/Bid_Package_6_VPAT_r2.3.pdf-2654364088fb4d44a9d23c1bcb7a9370.pdf">
                  Download PDF
                </a>
              </td>
            </tr>
            <tr>
              <td>UiPath</td>
              <td>Assistant</td>
              <td>October 2021</td>
              <td>2021.10 VPAT 2.4WCAG</td>
              <td>
                <a href="http://52.254.20.191/uploads/Assistant%20VPAT%20v%202021.10.pdf-5e10a0fcfb624cbebfd334f19a705d3d.pdf">
                  Download PDF
                </a>
              </td>
            </tr>
            <tr>
              <td>UiPath</td>
              <td>Automation Suite</td>
              <td>October 2021</td>
              <td>2021.10 VPAT 2.4WCAG</td>
              <td>
                <a href="http://52.254.20.191/uploads/Automation%20Suite%20v%202021.10%20VPAT.pdf-8732fc667fd04715aad8c08c9028f8d3.pdf">
                  Download PDF
                </a>
              </td>
            </tr>
            <tr>
              <td>UiPath</td>
              <td>Insights Cloud</td>
              <td>October 2021</td>
              <td>2021.10 VPAT 2.4WCAG</td>
              <td>
                <a href="http://52.254.20.191/uploads/Insights%20VPAT%202021.10.pdf-6c3c5e322f13497d8c32a9c60b605e36.pdf">
                  Download PDF
                </a>
              </td>
            </tr>
            <tr>
              <td>UiPath</td>
              <td>Orchestrator</td>
              <td>October 2021</td>
              <td>2021.10 VPAT 2.4WCAG</td>
              <td>
                <a href="http://52.254.20.191/uploads/Orchestrator%20VPAT%202021.10.pdf-891aaea409824743b0f1620bc54d7581.pdf">
                  Download PDF
                </a>
              </td>
            </tr>
            <tr>
              <td>UiPath</td>
              <td>Process Mining</td>
              <td>September 2021</td>
              <td>2021.10 VPAT 2.4WCAG</td>
              <td>
                <a href="http://52.254.20.191/uploads/process%20mining.pdf-17635328ef034dabafd9b792331ea5de.pdf">
                  Download PDF
                </a>
              </td>
            </tr>

            <tr>
              <td>UiPath</td>
              <td>Studio and Activities</td>
              <td>October 2021</td>
              <td>2021.10 VPAT 2.4WCAG</td>
              <td>
                <a href="http://52.254.20.191/uploads/Studio%20v2021.10.X%20and%20Activities%20VPAT.pdf-d58193337cf94c378ca82184182bf3e6.pdf">
                  Download PDF
                </a>
              </td>
            </tr>
            <tr>
              <td>UiPath</td>
              <td>StudioX and Activities</td>
              <td>October 2021</td>
              <td>2021.10 VPAT 2.4WCAG</td>
              <td>
                <a href="http://52.254.20.191/uploads/StudioX%20and%20Activities%20VPAT%20v%202021.10.pdf-b602c133b511418d817299d337f939c9.pdf">
                  Download PDF
                </a>
              </td>
            </tr>
            <tr>
              <td>UiPath</td>
              <td>Task Capture</td>
              <td>October 2021</td>
              <td>2021.10 VPAT 2.4WCAG</td>
              <td>
                <a href="http://52.254.20.191/uploads/Task%20Capture.pdf-9b237d10c3674682bdbc1a64d7e7e4b2.pdf">
                  Download PDF
                </a>
              </td>
            </tr>
            <tr>
              <td>UiPath</td>
              <td>Test Manager</td>
              <td>October 2021</td>
              <td>2021.10 VPAT 2.4WCAG</td>
              <td>
                <a href="http://52.254.20.191/uploads/Test%20Manager.pdf-a9aae9ca725e45f6b6924ae0fc8b2822.pdf">
                  Download PDF
                </a>
              </td>
            </tr>
            <tr>
              <td>UiPath</td>
              <td>
                UiPath Robot <br /> UiPath Studio <br />
                UiPath Orchestrator
              </td>
              <td>December 2018</td>
              <td>2021.4 VPAT 2.3 WCAG</td>
              <td>
                <a href="http://52.254.20.191/uploads/Bid%20Package%206%20VPAT%20r2.3.pdf-eb9a9da1a8d04305b0a62765c7170509.pdf">
                  Download PDF
                </a>
              </td>
            </tr>
          </table>
        </Col> */}
      </PanelFoundation>
      <SwitchComponent
        evenClassName={`bg-light text-primary pt-5 pb-5`}
        oddClassName={`bg-light text-primary pt-5 pb-5`}
        contents={[
          {
            heading: "Improve Citizen and Employee Satisfaction",
            image: <img src={image1} />,
            // image: <Image fluid={it_overview_image.childImageSharp.fluid} />,
            content: (
              <p>
                NITCO’s goal as a trusted public sector partner is to help find
                and support our clients to improve citizen and employee
                satisfaction with innovative, emerging technology solutions. Our
                incredibly talented team is certified in multiple digital
                automation technologies by our technology partners like Boomi,
                Kore.ai, and UiPath.
                <br /> <br />
                We do this by:
                <br /> <br />
                <ul>
                  <li>Guaranteeing the quality of our services</li>
                  <li>Earning your trust</li>
                  <li>Partnering with you for your success</li>
                  <li>Providing a secure solution and services</li>
                </ul>
              </p>
            )
            // <ReactMarkdown
            //   source={overview_description}
            //   escapeHtml={false}
            //   renderers={{ link: LinkRenderer }}
            // />
          },
          {
            heading: "Key Benefits of Digital Process Automation",
            image: <img src={image2} />,
            // image: <Image fluid={it_overview_image.childImageSharp.fluid} />,
            content: (
              <p>
                <ul>
                  <b>Organizational Service Strategy</b>
                  <br /> <br />
                  <li>
                    Digital automation overcomes the burden of manual processes,
                    which in turn, provide better employee and citizen
                    experiences.
                  </li>
                  <li>
                    From cost savings and reducing data entry error to
                    satisfying mandatory compliance requirements, many business
                    processes can be automated.
                  </li>
                  <br /> <br />
                  <b>Workforce Changes</b>
                  <br /> <br />
                  <li>
                    The pandemic implemented the global lockdown, which forced
                    all workforces to continue remote working. In such a
                    scenario, RPA or other automation solutions allowed
                    organizations to remain operational.
                  </li>
                  <li>
                    Conversational AI and RPA can handle periods of seasonal
                    demand for services like tax processing or handling
                    questions about elections.
                  </li>
                  <br /> <br />
                  <b>Organizational Survival</b>
                  <br /> <br />
                  <li>
                    From McKinsey & Company to Harvard Business Review to
                    Deloitte to Forrester Research and others, reports show
                    automation as the key to short-term survival and long-term
                    success in the new future of work. These experts point to
                    the pandemic as a key factor in accelerated digital
                    transformation, with automation as the technology at the
                    center of it all.
                  </li>
                </ul>
              </p>
            )
          }
        ]}
      />

      <SwitchComponent
        evenClassName={`bg-light text-primary pt-5 pb-5`}
        oddClassName={`bg-light text-primary pt-5 pb-5`}
        contents={[
          {
            heading: "Why you Should Partner with NITCO Inc.",
            image: <img src={image3} />,
            content: (
              <p>
                Our services are available to our clients in the US during
                regular business hours, Central Time Zone. We offer development
                and support and maintenance services using either all US-based
                resources or a hybrid resource model, which combines NITCO
                skilled resources from our India office along with management,
                technical, and functional oversight from our corporate office in
                Katy, Texas. This approach offers the flexibility of support
                coverage, choice of client preference, and business compliance
                requirements while maintaining our exacting standards for
                quality control and clear communication. We offer highly
                qualified and experienced resources in artificial intelligence,
                chatbots, robotic process automation, cloud integrations, and
                other emerging technologies. We partner as a reseller or service
                provider with leading software companies in these areas to
                provide complete solutions when possible or customize solutions
                for the specific client needs. Our experience and certifications
                with these companies allows us to implement their software
                efficiently and enable the functionality or integrations
                required based upon their software more quickly due to our
                in-depth technical knowledge.
              </p>
            )
          }
        ]}
      />
      <PanelFoundation rootClassName={`bg-light text-primary pt-5 pb-5`}>
        <div className={"table"}>
          <div className={"row"}>
            <div className={"col"}>
                <ul className={`one-column`}>
                  <h3>NITCO’s Digital Process Automation Solutions</h3>
                  <div>
                    Nitco is a reseller for
                    <br></br>
                    <br></br>
                    <ul>
                      <li>
                        Intelligent Automation including Chatbots, Conversational AI
                        and Intelligent Document Processing
                      </li>
                      <li>
                        <a>Robotic Process Automation</a> (RPA) including
                        Implementation, Process Assessments, ROI Analysis, Training,
                        Design and Development
                      </li>
                      <li>
                        Middleware Integrations including Implementation, Training,
                        Design and Development
                      </li>
                    </ul>
                  </div>
                </ul>
            </div>
            <div className={"col"}>
              <ul className={`one-column`}>
                  <h3>NITCO’s Digital Process Automation Services</h3>
                  <div>
                    NITCO is a certified service partner for:
                    <br></br>
                    <br></br>
                    <ul>
                      <li>Integration Managed Services</li>
                      <li>RPA as a Service (RPAaaS)</li>
                      <li>RPA Managed Services</li>
                      <li>Technical Staff Augmentation</li>
                    </ul>
                  </div>
                </ul>
            </div>
          </div>
        </div>
      </PanelFoundation>
      <SwitchComponent
        evenClassName={`bg-light text-primary pt-5 pb-5`}
        oddClassName={`bg-light text-primary pt-5 pb-5`}
        contents={[
          {
            heading: "NITCO’s Public Sector Contracts",
            image: <img src={image4} />,
            content: (
              <p>
                 <ul>
                  <li>State of Texas DIR Cooperative Contract {" "}
                    <a href="https://dir.texas.gov/contracts/dir-cpo-4725">
                      #DIR-CPO-4725
                    </a>
                  </li>
                  <li>State of Oklahoma IT Staff Augmentation {" "}
                    <a href="https://www.ok.gov/dcs/solicit/app/solicitationDetail.php?conID=4549">
                      #SW1025NC
                    </a>
                  </li>
                  <li>TIPS-USA Technology Solutions Products and Services {" "}
                    <a href="https://www.tips-usa.com/vendorProfile.cfm?RecordID=DF7B0D7E9919C8A4FE042BAED81A0C73">
                      #230105
                    </a>
                  </li>
                  <li>State of Oklahoma DBITs Contract {" "}
                    <a href="https://www.ok.gov/dcs/solicit/app/solicitationDetail.php?conID=4616">
                      #SW1050NIT
                    </a>
                  </li>
                </ul>
              </p>
            )
          }
        ]}
      />
      <div id="hubSpotFormOverlay" className={`${s.hubSpotFormOverlay}`}>
        <div className={`${s.hubSpotFormOverlayDiv}`}>
          <div className={`${s.hubSpotFormHeader}`}>
            <span className={`${s.hubSpotFormHeaderDescription}`}>
              Request a new Quote
            </span>
            <span
              className={`${s.hubSpotFormOverlayCloseButton}`}
              onClick={() => {
                showFormOverlay(false);
              }}
            >
              X
            </span>
          </div>
          <HubSpotForm
            formId="c052d59c-c46f-4821-a9d7-a3b5e73f68cb"
            replacementElement={
              <div
                className={"container"}
                style={{ justifyContent: "center", alignItems: "center" }}
              >
                <div
                  className={`container`}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <BlogSubscribeFormReplacementMessage />
                </div>
                <div
                  className={"container"}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <button
                    className="btn btn-primary ml-4"
                    onClick={() => {
                      showFormOverlay(false);
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
            }
          />
        </div>
      </div>
    </>
  );
};

export default PublicSectorServices;
